import {React} from 'react';

export default function NotFound() {
  return (
      <div className="container d-flex justify-content-center align-items-center" style={{
          height:'100vh',
      }}>
          <h4><strong>404</strong> | Not Found</h4>
      </div>
  );
}